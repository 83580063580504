<template>
	<v-row no-gutters>
		<v-dialog v-model="dialog" persistent>
			<v-card min-width="60vw" max-width="80vw">
				<v-card-title>Regras de Comissionamento</v-card-title>
				<v-divider />
				<v-card-text class="pa-4">
					<v-window v-model="step">
						<v-window-item :value="1">
							<fieldset class="pa-3">
								<legend>Cabeçalho da campanha</legend>
								<v-row>
									<v-col cols="12" class="mb-n8">
										<v-text-field v-model="regra.campanha" outlined label="Nome da campanha" dense></v-text-field>
									</v-col>
									<v-col class="mb-n6">
										<InputDatePicker
											:disabled="carregando"
											v-model="regra.dtinicio"
											label="Data inicial"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col>
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtfim"
											label="Data final"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
								</v-row>
							</fieldset>
						</v-window-item>
						<v-window-item :value="2">
							<fieldset class="pa-3 mb-4">
								<legend>Parâmetros de meta</legend>
								<v-row>
									<v-col>
										<v-text-field
											v-model="regra.metainicial"
											outlined
											class="mb-n6"
											label="Meta inicial"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.metafinal"
											outlined
											class="mb-n6"
											label="Meta final"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.metacoeficiente"
											outlined
											class="mb-n6"
											label="Coeficiente de meta"
											dense
											type="number"
										></v-text-field>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="pa-3 mb-4">
								<legend>Parâmetros de margem</legend>
								<v-row>
									<v-col>
										<v-text-field
											v-model="regra.margeminicial"
											outlined
											class="mb-n6"
											label="Margem inicial"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.margemfinal"
											outlined
											class="mb-n6"
											label="Margem final"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.margemcoeficiente"
											outlined
											class="mb-n6"
											label="Coeficiente de margem"
											dense
											type="number"
										></v-text-field>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="pa-3">
								<legend>Parâmetros de crescimento</legend>
								<v-row>
									<v-col>
										<v-text-field
											v-model="regra.crescimentoinicial"
											outlined
											class="mb-n6"
											label="Crescimento mínimo"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.crescimentofinal"
											outlined
											class="mb-n6"
											label="Crescimento máximo"
											dense
											type="number"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="regra.crescimentocoefiente"
											outlined
											class="mb-n6"
											label="Coeficiente de crescimento"
											dense
											type="number"
										></v-text-field>
									</v-col>
								</v-row>
							</fieldset>
						</v-window-item>
						<v-window-item :value="3">
							<fieldset class="pa-3 mb-4">
								<legend>Definir filiais (branco para todas)</legend>
								<v-row>
									<v-col class="mb-n6">
										<v-autocomplete
											:items="filiaisFiltro"
											multiple
											clearable
											item-text="filial"
											item-value="idfilial"
											label="Filial"
											outlined
											dense
											:disabled="carregando"
											v-model="regra.idfiliais"
										>
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span
													v-if="index === 1"
													class="grey--text text-caption ml-1"
												>(+{{ regra.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="pa-3 mb-4">
								<legend>Definir seções (branco para todas)</legend>
								<v-row>
									<v-col class="mb-n6">
										<v-autocomplete
											:items="secoes.lista"
											multiple
											clearable
											item-text="secao"
											item-value="idsecao"
											label="Seção"
											outlined
											dense
											:disabled="carregando"
											v-model="regra.idsecoes"
										>
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.secao }}</template>
												<span
													v-if="index === 1"
													class="grey--text text-caption ml-1"
												>(+{{ regra.idsecoes.length - 1 }})</span>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="pa-3">
								<legend>Definir produtos (branco para todos)</legend>
								<v-row>
									<v-col class="mb-n6">
										<v-autocomplete
											:items="produtos.lista"
											multiple
											clearable
											item-text="produto"
											item-value="idproduto"
											label="Produto"
											outlined
											dense
											:disabled="carregando"
											v-model="regra.idprodutos"
										>
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.produto }}</template>
												<span
													v-if="index === 1"
													class="grey--text text-caption ml-1"
												>(+{{ regra.idprodutos.length - 1 }})</span>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
							</fieldset>
						</v-window-item>
					</v-window>
				</v-card-text>
				<v-card-actions class="pa-4 mt-n4 justify-space-between">
					<v-btn :disabled="step === 1" color="primary" text @click="step--">
						<v-icon class="ml-n1">mdi-chevron-left</v-icon>Voltar
					</v-btn>
					<v-item-group v-model="step" class="text-center">
						<v-item v-for="n in 4" :key="`btn-${n}`" v-show="n != 1" v-slot="{ active, toggle }">
							<v-btn :input-value="active" icon @click="toggle">
								<v-icon>mdi-record</v-icon>
							</v-btn>
						</v-item>
					</v-item-group>
					<v-btn :disabled="step === 3" color="primary" text @click="step++">
						Próximo
						<v-icon class="mr-n1">mdi-chevron-right</v-icon>
					</v-btn>
				</v-card-actions>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="primary" text @click="dialog = false, step = 1">Cancelar</v-btn>
					<v-btn color="primary" elevation="0">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" class="mb-n7">
			<v-card>
				<v-card-title>
					<v-row no-gutters>
						<v-col class="d-none d-md-block pr-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtinicio"
								label="Data inicial"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtfim"
								label="Data final"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
							<v-sheet class="text-center" height="100%">
								<v-row class="pa-3">
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtinicio"
											label="Data inicial"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtfim"
											label="Data final"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
									</v-col>
								</v-row>
							</v-sheet>
						</v-bottom-sheet>
						<v-col class="pl-1">
							<v-text-field
								class="d-flex d-md-none"
								:disabled="carregando"
								v-model="busca.busca"
								label="Buscar"
								outlined
								dense
								@keydown.enter="listar(0)"
								append-outer-icon="mdi-menu"
								@click:append-outer="sheet = !sheet"
							/>
							<v-text-field
								class="d-none d-md-block"
								:disabled="carregando"
								v-model="busca.busca"
								label="Buscar"
								outlined
								dense
								@keydown.enter="listar(0)"
							/>
						</v-col>
						<v-col cols="auto" class="ml-2">
							<v-btn
								:disabled="carregando"
								class="mt-1"
								color="primary"
								elevation="0"
								fab
								x-small
								@click="listar(0)"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
					<v-simple-table v-else>
						<thead>
							<tr>
								<th class="text-left">ID</th>
								<th class="text-left">Regra</th>
								<th class="text-left">Data Ínicio</th>
								<th class="text-left">Data Fim</th>
								<th class="text-center">Status</th>
								<th class="text-left">Filiais</th>
								<th class="text-left">Seções</th>
								<th class="text-left">Produtos</th>
								<th class="text-center">
									<v-btn elevation="0" rounded small color="primary" @click="dialog = true">Novo</v-btn>
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="dados.total > 0">
								<tr v-for="(u, i) in dados.lista" :key="i" class="text-uppercase">
									<td>{{u.idcampanha}}</td>
									<td>{{u.campanha}}</td>
									<td>{{u.dtini}}</td>
									<td>{{u.dtfim}}</td>
									<td class="text-center font-weight-bold">
										<v-chip v-if="u.status == 'A'" small color="success">Ativo</v-chip>
										<v-chip v-else small color="error">Inativo</v-chip>
									</td>
									<td>{{u.filiais}}</td>
									<td>{{u.secoes}}</td>
									<td>{{u.produtos}}</td>
									<td class="text-center">
										<v-btn fab x-small elevation="0" color="primary">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
										<v-btn fab x-small elevation="0" color="error" class="ml-1">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="9">Nenhum registro encontrado.</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
					<Paginacao
						:total="dados.total"
						@atualizar="listar"
						:carregando="carregando"
						:pg="pgSelecionada"
					/>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
import Paginacao from "../Widgets/Paginacao.vue";
export default {
	name: "ComissaoConfiguracao",
	mixins: [mixinFilial],
	components: { InputDatePicker, Paginacao },
	data: () => ({
		step: 0,
		dialog: false,
		carregando: false,
		carregandoSkeleton: false,
		sheet: false,
		dados: { total: 0 },
		secoes: {},
		produtos: {},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idstatus: null,
			idsituacao: null,
		},
		regra: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idstatus: null,
			idsituacao: null,
		},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/regra/listar`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					busca: this.busca.busca || null,
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		listarSecoes() {
			this.secoes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/secao/listar`, {
					idfabricante: null,
				})
				.then((res) => {
					this.secoes = res.data;
					this.secoes.lista = this.secoes.lista.map((v) => {
						return {
							idsecao: v.idsecao,
							secao: `${v.idsecao} - ${v.secao}`,
						};
					});
				});
		},
		listarProdutos() {
			this.produtos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/filtro/listar`, {})
				.then((res) => {
					this.produtos = res.data;
					this.produtos.lista = this.produtos.lista.map((v) => {
						return {
							idproduto: v.idproduto,
							produto: `${v.idproduto} - ${v.produto}`,
						};
					});
				});
		},
		async init() {
			await this.listarSecoes();
			await this.listarProdutos();
			await this.listar(0);
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
fieldset {
	padding: 10px;
	border: solid rgba(37, 37, 37, 0.429) 1px;
	border-radius: 4px;
}
fieldset legend {
	padding: 0px 5px;
}
</style> 